<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col>
                        <h3>
                            {{ data.name }}
                        </h3>
                    </b-col>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.vdi.pools.headers.general') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.id') }}:</b-td>
                                    <b-td>{{ data.id }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.name') }}:</b-td>
                                    <b-td>{{ data.name }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.description') }}:</b-td>
                                    <b-td>{{ data.description }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.datastore') }}:</b-td>
                                    <b-td>{{ data.vCenterDatastoreRef }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.datastoreName') }}:</b-td>
                                    <b-td>{{ data.vCenterDatastoreName }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.datastoreType') }}:</b-td>
                                    <b-td>{{ data.vCenterDatastoreType }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.capacity') }}:</b-td>
                                    <b-td>
                                        <span v-if="data.free">
                                            {{ $t('pages.vdi.datastores.detail.capacityTotal') }}: {{ data.total }}<br>
                                            {{ $t('pages.vdi.datastores.detail.capacityFree') }}: {{ data.free }}<br>
                                            {{ $t('pages.vdi.datastores.detail.capacityUsed') }}: {{ data.used }} ({{ data.capacityPercentage.toFixed(1) }}%)<br>
                                            <b-progress
                                                style="width: 100%; min-width: 75px"
                                                striped
                                            >
                                                <b-progress-bar
                                                    :value="data.capacityPercentage"
                                                    :variant="data.capacityState"
                                                ></b-progress-bar>
                                            </b-progress>
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.datastores.detail.createdAt') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datastoresService from "../../../services/datastoresService";
import api from "../../../services/api";
import fileService from "../../../services/fileService";

export default {
    name: "DatastoresDetail",
    props: ["id"],
    data: () => ({
        data: {},
        datastores: []
    }),
    mounted() {
        this.loadData();
        this.loadDatastores();
    },
    methods: {
        loadData() {
            datastoresService.get(this.id).then(response => {
                // Merge vCenter datastore object into DB datastore object for mass storage
                const datastoreObj = this.datastores.find(i => i.datastore === response.data.vCenterDatastoreRef);
                if(datastoreObj){
                    response.data.free = fileService.bytesToSize(datastoreObj.free_space)
                    response.data.total = fileService.bytesToSize(datastoreObj.capacity)
                    response.data.used = fileService.bytesToSize(datastoreObj.capacity - datastoreObj.free_space)
                    response.data.capacityPercentage = (datastoreObj.capacity - datastoreObj.free_space) / datastoreObj.capacity * 100;
                    response.data.capacityState = datastoresService.getCapacityState(response.data.capacityPercentage)
                }
                this.data = response.data;
            }).catch(error => {
                this.errorHandler(error);
            });
        },
        loadDatastores() {
            api().get("vCenter/datastores").then(response => {
                this.datastores = response.data;
                this.loadData();
            }).catch(error => {
                this.loadData();
            });
        },
    }
};
</script>

<style scoped>

</style>
